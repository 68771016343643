export const APIS = {
  bseUrl8445: "https://app.smartdietplanner.com:8445/api/",
  baseurl: "https://app.smartdietplanner.com:8444/",
  userBaseUrl: "https://app.smartdietplanner.com/api/",
  mainUrl: "https://app.smartdietplanner.com",
  baseurl4444: "https://app.smartdietplanner.com:4444",
  baseurlApi: "https://app.smartdietplanner.com:4444/api/",
  nodeBaseUrl: "https://nodeapi.smartdietplanner.com/api/",

  pythonBaseUrl: "https://pythonapi.smartdietplanner.com/",
};
export const SUBAPIS = {
  login: "login",
  refs: "refs",
  summary: "summary",
  search: "customers?",
  updateUser: "customers",
  getToken: "getToken?",
  updateExpDate: "customer-update/update-premium",
  updateReferral: "customer-update/update-referral",
  fetchDietPlanNames: "custom/dietPlan/fetchDietPlanNames",
  fetchSlotsDetails: "custom/dietPlan/fetchSlotsDetails",
  updateSlotsDetails: "custom/dietPlan/updateSlot",
  fetchAllCategories: "custom/dietPlan/fetchAllCategories",
  updateCategory: "custom/dietPlan/updateCategory",
  updateFoodItems: "custom/dietPlan/updateFoodItems",
  fetchFoodDetails: "custom/dietPlan/fetchFoodDetails",
  user: "user",
  defaultDetail: "defaultDetail",
  fetchCustomerProfile: "fetchCustomerProfile?custId=",
  updateCustomerDetails: "updateCustomerDetails",
  saveDietRecall: "saveDietRecall?userId=",
  getDietRecall: "getDietRecall?userId=",
};
