import { Component, Input, OnInit } from '@angular/core';
import { TemplateService } from '../services';

@Component({
  selector: 'app-counter-strip',
  templateUrl: './counter-strip.component.html',
  styleUrls: ['./counter-strip.component.scss'],
})
export class CounterStripComponent implements OnInit {
  @Input() count: number;
  hasExpired: boolean = true;
  dietitianEmail: string = localStorage.getItem("loginEmail");
  planExpiry: string;

  constructor(
    private templateService: TemplateService,
  ) { }

  ngOnInit() {
    console.log("DieticianName >> ", localStorage.getItem("dietitianName"));
    console.log("Runningf")
    const query = `mail=${this.dietitianEmail}`;

    this.templateService.getDietitianRecord(query).subscribe(
      (res) => {
        console.log("Can AddResult ::--> ", res);
        this.count = 5 - res["noOfUsersCanBeAdded"];
        // planExpiryDate
        console.log("CCount :: ", this.count)
        if (res["planExpiryDate"]) {
          const expiryDate = new Date(res["planExpiryDate"]);

          console.log(":::  -----  line 32 -----::: ", expiryDate);
          // Get today's date (without time component)
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Set the time to 00:00 to only compare the date

          console.log("Expiry date:: ", expiryDate);

          // Compare expiryDate with today
          this.hasExpired = expiryDate < today;
          this.planExpiry = expiryDate.toISOString().split("T")[0];
        } else {
          this.hasExpired = true;
        }

        console.log("Has Expired ::?? ", this.hasExpired);
      },
      (err) => {
        console.log("error", err);
      }
    );
  }


}
