import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { APIS, CONSTANTS } from 'src/app/core/constants/constants';
import { SUBAPIS,APIS as AP } from "../../shared/constants/constants";
import { HttpService } from "../http/http.service";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private httpService: HttpService,private http:HttpClient) { }

  signIn(data: any): Observable<any> {
    return this.httpService.post(SUBAPIS.login, data);
  }
  refs(): Observable<any> {
    return this.httpService.get(SUBAPIS.refs);
  }
  leadSummary(): Observable<any> {
    return this.httpService.get(SUBAPIS.summary);
  }
  searchLead(searchQuery): Observable<any> {
    return this.httpService.get(SUBAPIS.search + searchQuery);
  }

  languagePref(language,userId){
    return this.httpService.post(`updateProfile?userId=${userId}`,{"languagePref": language}); 
  }

  readyToRelease(userId,date,isready){
     console.log("userId,date,isready",userId,date,isready);
     
    return this.httpService.post(`updateDietPlan?userId=${userId}&date=${date}&releaseReady=${isready}`,); 
  }

  updateUser(id, data: any): Observable<any> {
    return this.httpService.put(SUBAPIS.updateUser+"/"+id, data);
  }

  getUserToken(email){
    return this.httpService.getUser(SUBAPIS.getToken+"email="+email);
  }

  updateExpDate(data: any): Observable<any> {
    return this.httpService.post(SUBAPIS.updateExpDate, data);
  }

  updatRefferal(data: any): Observable<any> {
    return this.httpService.post(SUBAPIS.updateReferral, data);
  }

  fetchDietPlanNames(){
    return this.httpService.get(SUBAPIS.fetchDietPlanNames);
  }
  fetchSlotsDetails(email,dietname){
    return this.httpService.get(SUBAPIS.fetchSlotsDetails+`?email=${email}&dietPlanName=${dietname}`);
  }
  updateSlotDetail(payload,email,dietname){
    return this.httpService.post(SUBAPIS.updateSlotsDetails+`?email=${email}&selectedDietPlan=${dietname}`,payload);
  }

  updatediets(username,reqBody): Observable<any> {
    return this.httpService.put(SUBAPIS.user+`/${username}`,reqBody);
  }

  fetchAllCategories(payload){
    return this.httpService.get(SUBAPIS.fetchAllCategories+`?email=${payload.email}&slot=${payload.slot}&dietPlanName=${payload.dietPlan}`);
  }
  updateAllCategories(payload){
    return this.httpService.post(SUBAPIS.updateCategory+`?email=${payload.email}&slot=${payload.slot}`,payload.data);
  }

  fetchFoodDetails(payload){
    return this.httpService.get(SUBAPIS.fetchFoodDetails+`?email=${payload.email}&category=${payload.cat}&slot=${payload.slot}&dietPlanName=${payload.dietPlan}`);
  }
  updateFoodItems(payload){
    return this.httpService.post(SUBAPIS.updateFoodItems+`?email=${payload.email}&slot=${payload.slot}&category=${payload.cat}`,payload.data);
  }
  
  getPreferences(payload){
    console.log("payload",payload);
    return this.httpService.get(SUBAPIS.fetchCustomerProfile+`${payload.email}`);
  }
  updateDietPref(payload){
    return this.httpService.post(SUBAPIS.updateCustomerDetails,payload);
  }
  
  userAdd(reqBody): Observable<any> {
    return this.httpService.post(SUBAPIS.user,reqBody);
  }
  userSearch(username): Observable<any> {
    return this.httpService.get(SUBAPIS.user+`/${username}`);
  }
  userUpdate(username,reqBody): Observable<any> {
    return this.httpService.put(SUBAPIS.user+`/${username}`,reqBody);
  }
  userDelete(username): Observable<any> {
    return this.httpService.delete(SUBAPIS.user+`/${username}`);
  }
  getDietPlans():Observable<any>{
    return this.httpService.get(SUBAPIS.user);
  }
  defaultData():Observable<any>{
    const url = SUBAPIS.defaultDetail;
     return this.httpService.getUserDefault(url);
   }
}
