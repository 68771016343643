import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CONSTANTS } from "../core/constants/constants";
import { AppService } from "../services/app/app.service";
import { AppService as appSer } from "../../app/app.service";
import { AppService as appS } from "../app.service";
import { UTILITIES } from "../core/utility/utilities";
import { NgTinyUrlService } from "ng-tiny-url";
import { LoaderService, TemplateService } from "../services";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @Input() menuName: any;
  search: any = localStorage.getItem("email");
  isSearch = false;
  searchData: any = "";
  lifeStyle: any;
  dietPlan = [
    { id: "fiteloWaterRetention", name: "Water retention" },
    { id: "fiteloWeightLoss", name: "High Protein fiber" },
    { id: "weightLoss", name: "Weight Loss" },
    { id: "muscleGain_morning", name: "Muscle Gain Morning" },
    { id: "muscleGain_evening", name: "Muscle Gain Evening" },
    { id: "fatShredding_morning", name: "Fat Shredding Morning" },
    { id: "fatShredding_evening", name: "Fat Shredding Evening" },
    { id: "diabetes", name: "Diabetes" },
    { id: "pcos", name: "PCOS" },
    { id: "cholesterol", name: "Cholesterol" },
    { id: "hypertension", name: "Hypertension" },
  ];
  planChoosen: any;
  profileInititals = "";
  updateTargetCal: any;
  username = "";
  recommendedTargetCal: any;
  isHindi: boolean = true;
  isDietitiian: boolean =
    localStorage.getItem("companyKey") === null ? false : true;
  userIdInput = "";
  key = "";
  isOpenPopup = false;
  link = "";
  link1 = "";
  profileData: any = {};
  diseases = [];
  diseases1 = [];
  alergies = ["SF,SO", "ML", "F", "E", "N", "G"];
  mealPref;
  defaultData: any;
  activityName;
  searchAllowed: boolean = localStorage.getItem("searchAllowed") === "true";
  community = [];
  isOpen = false;
  dietitianEmail: string = localStorage.getItem("loginEmail");
  isIndividual = localStorage.getItem("isIndividual");
  url = "";
  avatar: string = null;
  hasExpired: boolean = true;
  planExpiry: string;
  count: number = 0;
  @Output() countChanged = new EventEmitter<number>();

  userExisted: boolean = false;
  apiHitted = false;
  haveAccess: boolean = true;

  constructor(
    private router: Router,
    private appS: appSer,
    private appServe: AppService,
    private appservice: appS,
    private utilities: UTILITIES,
    private loaderService: LoaderService,
    private tinyUrl: NgTinyUrlService,
    private templateService: TemplateService,
    private activateRoute: ActivatedRoute
  ) {
    console.log("dasdadas", localStorage.getItem("menu"));

    if (
      localStorage.getItem("menu") !== "" &&
      localStorage.getItem("menu") !== "undefined"
    ) {
      this.menuName = JSON.parse(
        localStorage.getItem("menu") == "undefined"
          ? "{}"
          : localStorage.getItem("menu")
      );
    }
  }
  openPopup() {
    this.isOpen = !this.isOpen;
  }
  gotoProfile() {
    this.router.navigate(["edt-d"]);
  }
  title: any;
  ngOnInit() {
    this.searchData = "";
    this.username = "";
    console.log("DieticianName >> ", localStorage.getItem("dietitianName"));
    const query = `mail=${this.dietitianEmail}`;

    this.templateService.getDietitianRecord(query).subscribe(
      (res) => {
        console.log("Can AddResult ::--> ", res);
        this.count = 5 - res["noOfUsersCanBeAdded"];
        this.countChanged.emit(this.count);
        if (res["planExpiryDate"]) {
          const expiryDate = new Date(res["planExpiryDate"]);

          // Get today's date (without time component)
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Set the time to 00:00 to only compare the date

          // Compare expiryDate with today
          this.hasExpired = expiryDate < today;
          this.planExpiry = expiryDate.toISOString().split("T")[0];
        } else {
          this.hasExpired = true;
        }
        const file = res["image"];
        if (file) {
          // https://nodeapi.smartdietplanner.com/api/getResFromPath/uploads/1725988948823-675168050.png
          this.avatar = `https://nodeapi.smartdietplanner.com/api/getResFromPath/${file}`;
        }
      },
      (err) => {
        this.utilities.hideLoader();
        console.log("error", err);
      }
    );

    if (
      localStorage
        .getItem("loginEmail")
        .toLowerCase()
        .includes("beatoapp.com".toLowerCase())
    ) {
      this.username = "beato";
    } else if (localStorage.getItem("dietitianName") !== null) {
      this.username = localStorage.getItem("dietitianName");
    } else {
      this.username = localStorage.getItem("loginEmail");
    }
    this.search = localStorage.getItem("email");
  }

  ngAfterViewInit() {
    if (localStorage.getItem("menu").length > 10) {
      this.menuName = JSON.parse(localStorage.getItem("menu"));
    } else {
      localStorage.setItem(
        "menu",
        `{
        "title":"Users Summary",
        "description":[{"name":"This section displays a summary of the latest 20 contacts","desc":[]},{"name":"It has actionable buttons for WhatsApp, scheduling calls, or viewing diet plan details.","desc":[]}],
        "menu":"Home",
        "subMenu":"",
        "activeIndex":0   
    }`
      );
      this.menuName = JSON.parse(localStorage.getItem("menu"));
    }
  }

  additionPreferences: any;
  searchDetails: any;
  additionalPref(additionalPref) {
    this.additionPreferences = additionalPref;
  }

  fetchProfile() {
    console.log("Inside Navbar:: >> ", this.search);
    if (!this.search?.trim()) {
      return;
    }

    let query = `userId=${this.search}&testVersion=${true}`;

    if (this.isIndividual === "true") {
      const dietitianId = localStorage.getItem("dietitianId");
      query += `&dietitianId=${dietitianId}`;
    } else {
      const companyId = localStorage.getItem("companyId");
      if (companyId === "smartdietplanner") {
        query += `&companyId=*`;
      } else {
        query += `&companyId=${companyId}`;
      }
    }

    this.loaderService
      .presentLoader("Fetching details for " + this.search + "...")
      .then((_) => {
        this.templateService.searchUser(query).subscribe(
          (profileData: any) => {
            // this.appS.searchProfile(this.search).then((profileData: any) => {
            // alert(profileData.length);
            this.loaderService.hideLoader();

            if (!profileData || profileData.length === 0) {
              this.utilities.presentAlert(
                "User does not exist. Please try again with correct Id."
              );
              return;
            } else if (profileData.length > 1) {
              this.isSearch = true;
              CONSTANTS.email = this.search;
              const id = this.search;
              localStorage.setItem("email", this.search);
              this.router.navigate(["dashboard"], {
                queryParams: { id },
              });

              return;
            } else {
              this.isSearch = true;
              CONSTANTS.email = this.search;
              localStorage.setItem("email", this.search);
            }
            this.searchData = profileData["_id"];
            console.log("profileData:-", profileData);
            this.lifeStyle = profileData?.lifeStyle;
            this.additionalPref(profileData["additionalPref"]);
            const data = profileData["profile"];
            if (data) {
              this.searchDetails = JSON.parse(JSON.stringify(profileData));
              localStorage.setItem("femail", "Yes");
              console.log("this.searchDetails", this.searchDetails);

              const planC = this.dietPlan.filter((item) => {
                return (
                  item.id === this.searchDetails?.additionalPref?.planChosen
                );
              });
              this.planChoosen = planC[0]?.name;
              this.updateTargetCal = this.searchDetails?.lifeStyle?.calories;
              this.recommendedTargetCal =
                this.searchDetails?.lifeStyle?.calories;
              const dd = JSON.parse(JSON.stringify(profileData));
              this.profileInititals = dd?.profile.name;
              // dd?.profile.name?.replace("  "," ")?.split(' ')[0][0].toUpperCase() +
              // (dd?.profile?.name?.replace("  "," ")?.split(' ')?.length>=2?dd?.profile?.name?.replace("  "," ")?.split(' ')[1][0].toUpperCase():dd?.profile?.name?.replace("  "," ")?.split(' ')[0][1].toUpperCase());

              this.isHindi =
                profileData["profile"]?.languagePref == undefined
                  ? false
                  : true;
              // this.search ='';
              this.appServe.getUserToken(this.search).subscribe(
                (response) => {
                  console.log(response);
                },
                (error) => {
                  console.log("User token ", error.error.text);
                  localStorage.setItem("personal_token", error.error.text);
                  console.log("Category Error", error);
                }
              );
              this.getProfile();
              this.getToken1();
            } else {
              localStorage.setItem("femail", "No");
              this.searchDetails = undefined;
            }
            localStorage.setItem("email", this.search);
            localStorage.setItem("femail", "Yes");
            localStorage.setItem("activeNum", "1");
            localStorage.setItem(
              "menu",
              `{
          "title":"View/Edit Profile",
          "description":[{"name":"This page allows you to view and update user information such as:",
          "desc":["Name",
          "Age",
          "Weight",
          "Height",
          "Activity level",
          "Food choices",
          "Disorders",
          "Allergies",
          "Diet Plan"]}],
          "menu":"Profile",
          "subMenu":"",
          "activeIndex":1          
      }`
            );
            this.router.navigate(["/profile-detail"]).then(() => {
              window.location.reload();
            });

            this.appS.getBeatoData(this.search).then(
              (res) => {
                console.log("response", res);
              },
              (err) => {
                console.log("error", err);
              }
            );
          },
          (err) => {
            this.loaderService.hideLoader();

            console.log("Error Response>> ", err);
            //  CONSTANTS.email = "";
            //  localStorage.setItem("email", "");
            this.isSearch = false;
            this.search = "";
            this.utilities.presentAlert(
              "User does not exist. Please try again with correct Id."
            );
          }
        );
      });
  }

  closePopup() {
    this.userExisted = false;
    this.apiHitted = false;
    this.isOpenPopup = false;
    this.profileData1 = [];
  }

  clearSearch() {
    this.search = "";
    localStorage.setItem("email", "");
    this.isSearch = false;
  }

  clearMessage() {
    this.link = "";
    this.link1 = "";
    this.key = "";
    this.userIdInput = "";
    this.userExisted = false;
    this.apiHitted = false;
    this.haveAccess = true;
  }
  getTinyUrl(url) {
    this.tinyUrl.shorten(url).subscribe(
      (res) => {
        this.link = res;
        this.link1 = res;
        this.utilities.hideLoader();
      },
      (err) => {
        this.utilities.hideLoader();
        this.utilities.presentAlert("Something went wrong. Please try again!");
        console.log("error", err);
      }
    );
  }

  profileCanBeAdded(callback: (profileData: any) => void) {
    console.log("Inside Navbar:: >> ", this.userIdInput);
    // alert(this.userIdInput);

    let query = `userId=${this.userIdInput}`;

    if (this.isIndividual === "true") {
      const dietitianId = localStorage.getItem("dietitianId");
      query += `&dietitianId=${dietitianId}`;
    } else {
      const companyId = localStorage.getItem("companyId");
      if (companyId === "smartdietplanner") {
        query += `&companyId=*`;
      } else {
        query += `&companyId=${companyId}`;
      }
    }

    return this.templateService.canAddProfile(query).subscribe(
      (profileData: any) => {
        debugger;
        this.profileData1 = profileData;
        console.log("ProfileData", profileData);
        callback(profileData);
      },
      (err) => {
        alert(JSON.stringify(err));
      }
    );
  }
  profileData1: any;
  isRegisteredUser() {
    this.profileCanBeAdded((profileCanBeAdded) => {
      // alert(JSON.stringify(profileCanBeAdded));
      if (profileCanBeAdded.status) {
        let clientIdSystem = "";
        if (localStorage.getItem("loginEmail") === "orthocure") {
          this.key = "orthocure2024";
          clientIdSystem = "orthocure";
        } else if (localStorage.getItem("loginEmail") === "fitelo") {
          this.key = "FITELO2023";
          clientIdSystem = "fitelo";
        } else if (localStorage.getItem("companyKey") !== null) {
          this.key = localStorage.getItem("companyKey");
          clientIdSystem = localStorage.getItem("urlClientId");
        }
        if (this.key === "" || this.userIdInput === "") {
          this.utilities.presentAlert("Key and Profile id is mandatory!");
          return;
        }
        this.utilities.showLoading();
        console.log(this.userIdInput);
        const payload = {
          profile: {
            email: this.userIdInput,
          },
        };
        // alert(clientIdSystem);
        this.appS
          .getTokenExternal(this.key, this.userIdInput)
          .subscribe((res1) => {
            // User is registered
            if (res1["code"] === "0000") {
              // const lnk = `https://testonboarding.smartdietplanner.com/read?token=${res1["accessToken"]}&clientId=${clientIdSystem}&type=1`;
              const lnk = `https://onboarding.smartdietplanner.com/read?token=${res1["accessToken"]}&clientId=${clientIdSystem}&type=1`;

              this.getTinyUrl(lnk);
              this.apiHitted = true;
              this.userExisted = true;
            } else {
              // User not registered
              this.appS.externalRegistration(this.key, payload).subscribe(
                (res) => {
                  if (res["code"] !== "0000") {
                    this.utilities.hideLoader();
                    this.utilities.presentAlert(res["message"]);
                  } else {
                    //  const lnk = `https://testonboarding.smartdietplanner.com/read?token=${res["access_token"]}&clientId=${clientIdSystem}&type=1`;
                    const lnk = `https://onboarding.smartdietplanner.com/read?token=${res["access_token"]}&clientId=${clientIdSystem}&type=1`;

                    this.getTinyUrl(lnk);
                    this.apiHitted = true;
                    this.userExisted = false;
                    this.newUserAddAction();
                  }
                  if (localStorage.getItem("dietitianId") != null) {
                    // dietitianId, actionId, userId,  actionName,  companyId
                    this.templateService
                      .handleDietitianAction(
                        localStorage.getItem("dietitianId"),
                        "668d74731073b512dd2b9850",
                        this.userIdInput,
                        "Add New User",
                        localStorage.getItem("companyId")
                      )
                      .subscribe((dRespondata) => {
                        console.log("success", res["access_token"]);
                      });
                  }
                },
                (err) => {
                  this.utilities.hideLoader();
                  console.log("error", err);
                }
              );
            }
          });

        // this.isOpenPopup = false;
      } else {
        this.apiHitted = true;
        this.userExisted = true;
        this.haveAccess = false;
      }
    });
  }
  copyMessage(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.link = "copied";
  }

  newUserAddAction() {
    if (this.isIndividual === "true") {
      this.templateService.newUserAddAction(this.dietitianEmail).subscribe(
        (res) => {
          console.log("New UserAddActionResult ::--> ", res);
          this.count++;
          this.countChanged.emit(this.count);
        },
        (err) => {
          this.utilities.hideLoader();
          console.log("error", err);
        }
      );
    }
  }

  // fetchProfile() {
  //   console.log("Inside Navbar:: >> ", this.search);
  //   this.searchEvent.emit(this.search);
  // }

  editFxnClick() {
    this.router.navigate(["edt-d"]);
  }

  getProfile() {
    this.profileData = [];
    this.appservice.getProfile().then((res) => {
      this.profileData = res;

      if (this.profileData?.profile?.subCategory === "weightloss") {
        this.profileData.profile.subCategory = "Weight Loss";
      }
      if (this.profileData?.profile?.subCategory === "weightmaintenance") {
        this.profileData.profile.subCategory = "Weight Maintenance";
      }
      if (this.profileData?.profile?.subCategory === "musclebuilding") {
        this.profileData.profile.subCategory = "Muscle Building";
      }
      if (this.profileData?.profile?.subCategory === "leanbody") {
        this.profileData.profile.subCategory = "Lean Body";
      }

      let h: any =
        this.profileData?.demographic?.height?.unit === "in"
          ? this.profileData?.demographic?.height?.value / 12
          : this.profileData?.demographic?.height?.value;
      if (this.profileData?.demographic?.height?.unit === "in") {
        console.log(h);
        h = h.toString().split(".");
        console.log(h);
        const h1: any = (h[1] / 0.0833333).toString().split("0")[0];
        console.log(h1);
        this.profileData.demographic.height.value = `${h[0]}' ${h1}"`;
      } else {
        this.profileData.demographic.height.value =
          this.profileData.demographic.height.value + " cm";
      }
      console.log(this.profileData);
    });
  }

  getToken1() {
    if (!localStorage.getItem("email")) {
      this.utilities.presentAlert("Please enter email");
    } else {
      this.appServe.getUserToken(localStorage.getItem("email")).subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log("User token ", error.error.text);
          this.appS.getDefaultData(error.error.text).then((res) => {
            this.diseases = [];
            this.diseases1 = [];
            this.alergies = ["SF,SO", "ML", "F", "E", "N", "G"];
            this.mealPref;
            this.community = [];
            this.defaultData = res;
            this.activityName = this.defaultData.otherMaster.activities.filter(
              (item) => {
                return (
                  item.code ===
                  this.searchDetails?.lifeStyle?.activities["code"]
                );
              }
            );

            console.log("Activity Response:: ", this.defaultData);

            for (
              let index = 0;
              index < this.searchDetails?.lifeStyle?.diseases.length;
              index++
            ) {
              for (
                let j = 0;
                j < this.defaultData?.otherMaster?.diseases.length;
                j++
              ) {
                if (
                  this.defaultData?.otherMaster?.diseases[j].code ===
                  this.searchDetails?.lifeStyle?.diseases[index]
                ) {
                  if (
                    this.alergies.includes(
                      this.defaultData?.otherMaster?.diseases[j].code
                    )
                  ) {
                    this.diseases1.push(
                      this.defaultData?.otherMaster?.diseases[j].value
                    );
                  } else {
                    this.diseases.push(
                      this.defaultData?.otherMaster?.diseases[j].value
                    );
                  }
                }
              }
            }
            this.mealPref = this.defaultData?.otherMaster?.foodPref?.filter(
              (item) => {
                return item.code === this.searchDetails?.lifeStyle?.foodType;
              }
            );
            for (
              let index = 0;
              index < this.searchDetails?.lifeStyle?.communities.length;
              index++
            ) {
              for (
                let j = 0;
                j < this.defaultData?.otherMaster?.community.length;
                j++
              ) {
                if (
                  this.defaultData?.otherMaster?.community[j].code ===
                  this.searchDetails?.lifeStyle?.communities[index]
                ) {
                  this.community.push(
                    this.defaultData?.otherMaster?.community[j].value
                  );
                }
              }
            }
          });

          this.appS.getOnePlan1(error.error.text).then((res) => {
            console.log("getOnePlan", res);
          });
          console.log("Category Error", error);
        }
      );
    }
  }

  registerUser() {
    console.log(
      "IsIndividual :: > ",
      this.isIndividual,
      " || type: ",
      typeof this.isIndividual
    );
    if (this.isIndividual === "true") {
      this.templateService.canAddUser(this.dietitianEmail).subscribe(
        (res) => {
          console.log("Result ::--> ", res);
          if (res["canAdd"]) {
            this.isOpenPopup = true;
            this.link = "";
            this.link1 = "";
            this.key = "";
            this.userIdInput = "";
          } else {
            this.utilities.presentAlert(
              "As a free user you can manage 5 clients only . Be Premium to manage 150+ clients"
            );
          }
        },
        (err) => {
          this.utilities.hideLoader();
          console.log("error", err);
        }
      );
    } else {
      this.isOpenPopup = true;
      this.link = "";
      this.link1 = "";
      this.key = "";
      this.userIdInput = "";
    }
  }

  editProfile() {
    localStorage.setItem("email", this.userIdInput);
    localStorage.setItem("femail", "Yes");
    localStorage.setItem("activeNum", "1");
    localStorage.setItem(
      "menu",
      `{
        "title":"View/Edit Profile",
        "description":[{"name":"This page allows you to view and update user details.","desc":[]}],
        "menu":"Profile",
        "subMenu":"",
        "activeIndex":1          
    }`
    );
    this.router.navigate(["edit-profile"]).then(() => {
      window.location.reload();
    });
  }

  premiumHandler() {
    this.router.navigate(["dietitian-plan"]);
  }

  getFirstSubstringUserName(inputString: string) {
    // Split the input string by spaces
    var substrings = inputString.split(" ");

    // Return the first element
    var usernameResult = substrings[0];

    if (usernameResult.length > 13) {
      usernameResult = usernameResult.substring(0, 5) + "...";
    }

    return usernameResult;
  }
}
