import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { InterceptorProvider } from "./shared/interceptors/jwt.interceptor";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { IonicStorageModule } from "@ionic/storage";
import { SummaryUpdatePage } from "./modal/summary-update/summary-update.page";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { ComponentsModule } from "./components/components.module";
import { NativeStorage } from "@ionic-native/native-storage/ngx";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { ChangePlanComponent } from "./change-plan/change-plan.component";
import { SelectPlanPopupComponent } from "./select-plan-popup/select-plan-popup.component";
import { GaugeChartModule } from 'angular-gauge-chart';
import { NewlinePipe } from './newline.pipe';

@NgModule({
  declarations: [
    AppComponent,
    SummaryUpdatePage,
    ChangePlanComponent,
    SelectPlanPopupComponent,

   ],
  entryComponents: [SummaryUpdatePage],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
  }),
    // GaugeChartModule,
    IonicStorageModule.forRoot({
      name: "__mydbSDP",
      driverOrder: ["indexeddb", "sqlite", "websql", "localstorage"],
    }),
    // IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,

    // ConsumeVPageModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorProvider, multi: true },
    InAppBrowser,
    Keyboard,
    NativeStorage,
    Keyboard,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
