import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(private loadingController: LoadingController) { }

  async presentLoader(title: string, dur?: number, spin?: any) {
    const loading = await this.loadingController.create({
      message: title,
			translucent: true,
			spinner:  spin || "circles" || "crescent",
			keyboardClose: true,
    });
    await loading.present();
  }
  async hideLoader(){
    const res = await this.loadingController.dismiss().then(() => { }, () => { });
		return await res;
    //  const { role, data } = await loading.onDidDismiss();
    // console.log('Loading dismissed with role:', role);
  }
}
