import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ChangePlanComponent } from "./change-plan/change-plan.component";
import { AddUpdateCustomerComponent } from "./components/add-update-customer/add-update-customer.component";

const routes: Routes = [
  {
    path: "authenticate",
    loadChildren: () =>
      import("./authenticate/authenticate.module").then(
        (m) => m.AuthenticatePageModule
      ),
  },
  {
    path: "",
    loadChildren: () =>
      import("./tabs/tabs.module").then((m) => m.TabsPageModule),
  },
  // {
  //   path: '',
  //   loadChildren: () => import('./consume-v/consume-v.module').then( m => m.ConsumeVPageModule)
  // },
  {
    path: "search-lead",
    loadChildren: () =>
      import("./search-lead/search-lead.module").then(
        (m) => m.SearchLeadPageModule
      ),
  },
  {
    path: "consume",
    loadChildren: () =>
      import("./consume-v/consume-v.module").then((m) => m.ConsumeVPageModule),
  },
  {
    path: "options",
    loadChildren: () =>
      import("./options/options.module").then((m) => m.OptionsPageModule),
  },
  {
    path: "food-detail",
    loadChildren: () =>
      import("./food-detail/food-detail.module").then(
        (m) => m.FoodDetailPageModule
      ),
  },
  {
    path: "summary-lead",
    loadChildren: () =>
      import("./summary-lead/summary-lead.module").then(
        (m) => m.SummaryLeadPageModule
      ),
  },
  {
    path: "summary-update",
    loadChildren: () =>
      import("./modal/summary-update/summary-update.module").then(
        (m) => m.SummaryUpdatePageModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "select-diet",
    loadChildren: () =>
      import("./diet-plan/select-diet/select-diet.module").then(
        (m) => m.SelectDietPageModule
      ),
  },
  {
    path: "confirm-diet",
    loadChildren: () =>
      import("./diet-plan/confirm-diet/confirm-diet.module").then(
        (m) => m.ConfirmDietPageModule
      ),
  },
  {
    path: "update-slot",
    loadChildren: () =>
      import("./diet-plan/update-slot/update-slot.module").then(
        (m) => m.UpdateSlotPageModule
      ),
  },
  {
    path: "update-detail-slot",
    loadChildren: () =>
      import("./diet-plan/update-detail-slot/update-detail-slot.module").then(
        (m) => m.UpdateDetailSlotPageModule
      ),
  },
  {
    path: "users",
    loadChildren: () =>
      import("./users/users.module").then((m) => m.UsersPageModule),
  },
  {
    path: "users-list",
    loadChildren: () =>
      import("./users-list/users-list.module").then(
        (m) => m.UsersListPageModule
      ),
  },
  {
    path: "consume-v",
    loadChildren: () =>
      import("./consume-v/consume-v.module").then((m) => m.ConsumeVPageModule),
  },
  {
    path: "analysis",
    loadChildren: () =>
      import("./analysis/analysis.module").then((m) => m.AnalysisPageModule),
  },
  {
    path: "options",
    loadChildren: () =>
      import("./options/options.module").then((m) => m.OptionsPageModule),
  },
  {
    path: "options-new",
    loadChildren: () =>
      import("./diet-plan/update-slot-new/update-slot-new-routing.module").then(
        (m) => m.UpdateSlotNewPageRoutingModule
      ),
  },
  {
    path: "addupdate",
    component: AddUpdateCustomerComponent,
  },
  {
    path: "change-plan",
    component: ChangePlanComponent,
  },
  {
    path: "update-slot-new",
    loadChildren: () =>
      import("./diet-plan/update-slot-new/update-slot-new.module").then(
        (m) => m.UpdateSlotNewPageModule
      ),
  },
  {
    path: "deitician-search",
    loadChildren: () =>
      import("./deitician-search/deitician-search.module").then(
        (m) => m.DeiticianSearchPageModule
      ),
  },
  {
    path: "preference-diet",
    loadChildren: () =>
      import("./preference-diet/preference-diet.module").then(
        (m) => m.PreferenceDietPageModule
      ),
  },
  {
    path: "personalise",
    loadChildren: () =>
      import("./personalise/personalise.module").then(
        (m) => m.PersonalisePageModule
      ),
  },
  {
    path: "new-profile",
    loadChildren: () =>
      import("./new-profile/new-profile.module").then(
        (m) => m.NewProfilePageModule
      ),
  },
  {
    path: "update-slot-remarks",
    loadChildren: () =>
      import("./update-slot-remarks/update-slot-remarks.module").then(
        (m) => m.UpdateSlotRemarksPageModule
      ),
  },
  {
    path: "food-item-choice", //
    loadChildren: () =>
      import("../app/pages/manage-diet-choice/manage-diet-choice.module").then(
        (m) => m.ManageDietChoicePageModule
      ),
  },
  {
    path: "manage-diet", //
    loadChildren: () =>
      import("../app/pages/manage-diet/manage-diet.module").then(
        (m) => m.ManageDietPageModule
      ),
  },
  {
    path: "add-instructions",
    loadChildren: () =>
      import("./add-instructions/add-instructions.module").then(
        (m) => m.AddInstructionsPageModule
      ),
  },
  {
    path: "consult-qa",
    loadChildren: () =>
      import("./consult-qa/consult-qa.module").then(
        (m) => m.ConsultQaPageModule
      ),
  },
  {
    path: "manage-diet",
    loadChildren: () =>
      import("./pages/manage-diet/manage-diet.module").then(
        (m) => m.ManageDietPageModule
      ),
  },
  {
    path: "plan-expiry",
    loadChildren: () =>
      import("./plan-expiry/plan-expiry.module").then(
        (m) => m.PlanExpiryPageModule
      ),
  },
  {
    path: "template",
    loadChildren: () =>
      import("./template/template.module").then((m) => m.TemplatePageModule),
  },
  {
    path: "first-page",
    // loadChildren: () =>
    //   import("./first-page/first-page.module").then(
    //     (m) => m.FirstPagePageModule
    //   ),

    loadChildren: () =>
      import("./edt-d/edt-d.module").then((m) => m.EdtDPageModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardPageModule),
  },
  {
    path: "edit-profile",
    loadChildren: () =>
      import("./edit-profile/edit-profile.module").then(
        (m) => m.EditProfilePageModule
      ),
  },
  {
    path: "insights",
    loadChildren: () =>
      import("./insights/insights.module").then((m) => m.InsightsPageModule),
  },
  {
    path: "calory-chart",
    loadChildren: () =>
      import("./calory-chart/calory-chart.module").then(
        (m) => m.CaloryChartPageModule
      ),
  },
  {
    path: "edt-d",
    loadChildren: () =>
      import("./edt-d/edt-d.module").then((m) => m.EdtDPageModule),
  },
  {
    path: "health-parameters",
    loadChildren: () =>
      import("./health-parameters/health-parameters.module").then(
        (m) => m.HealthParametersPageModule
      ),
  },
  {
    path: "template-popup",
    loadChildren: () =>
      import("./template-popup/template-popup.module").then(
        (m) => m.TemplatePopupPageModule
      ),
  },
  {
    path: "faq",
    loadChildren: () => import("./faq/faq.module").then((m) => m.FaqPageModule),
  },
  {
    path: "dietitian-plan",
    loadChildren: () =>
      import("./dietitian-plan/dietitian-plan.module").then(
        (m) => m.DietitianPlanPageModule
      ),
  },
  {
    path: "profile-detail",
    loadChildren: () =>
      import("./profile-detail/profile-detail.module").then(
        (m) => m.ProfileDetailPageModule
      ),
  },
  {
    path: "food-search",
    loadChildren: () =>
      // ./food-search/food-search.module
      import("./food-search/food-search.module").then(
        (m) => m.DietPlanListPageModule
      ),
  },
  {
    path: "suggest-recipe",
    loadChildren: () =>
      import("./suggest-recipe/suggest-recipe.module").then(
        (m) => m.NwDietPlanPageModule
      ),
  },
  {
    path: "dietitian-info",
    loadChildren: () =>
      import("./dietitian-info/dietitian-info.module").then(
        (m) => m.DietitianInfoPageModule
      ),
  },
  {
    path: 'demo-video',
    loadChildren: () => import('./demo-video/demo-video.module').then( m => m.DemoVideoPageModule)
  },
  {
    path: 'new-food-detail',
    loadChildren: () => import('./new-food-detail/new-food-detail.module').then( m => m.NewFoodDetailPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
