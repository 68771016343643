import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppService, LoaderService } from "../../services";
import { Router } from "@angular/router";
import moment from 'moment';

@Component({
  selector: 'app-summary-update',
  templateUrl: './summary-update.page.html',
  styleUrls: ['./summary-update.page.scss'],
})
export class SummaryUpdatePage implements OnInit {
  leadData;
  remarkText:string = '';
  leadStatus:any;
  leadType: any;
  searchQueryType;
  searchQueryCode;
  updateRemarkValid : boolean = false;
    constructor(
    public modalController: ModalController,
    private appService: AppService,
    private router: Router,
    private loaderService: LoaderService
    ) {
     
     }

  ngOnInit() {
    console.log("leadData", this.leadData.leadInfo)
     
  }
  ionViewWillEnter(){
    this.leadType =  this.leadData.leadInfo.leadType;
    this.leadStatus =  this.leadData.leadInfo.status;

  }
  checkRemarkTextLength(value:any){    
    if(value.length<=25){
      //min length found. 
      this.updateRemarkValid = false
   } else {
    this.updateRemarkValid = true;
   }
  }

  closeModal(){
    this.modalController.dismiss();
  }

  leadStatusChange(data){
    console.log('leadStatusChange');
    this.leadStatus = data.target.value;
    
  }
  leadTypeChange(leadType){
    console.log('leadTypeChange');
    this.leadType = leadType.target.value;
  }
  updateSummary(){
    console.log("leadData", this.leadType)
    console.log("leadData", this.leadStatus)
    console.log("leadData", this.remarkText)
    let obj = {
      "dateOfCall": moment().format("YYYY-MM-DD"),
      "leadType": this.leadType,
      "option": this.leadData['leadInfo']['option'],
      "remarks": [
        this.remarkText
      ],
      "status": this.leadStatus ? this.leadStatus : this.leadData['leadInfo']['status'],
      "subOption": this.leadData['leadInfo']["subOption"],
      "timePreference": this.leadData['leadInfo']['timePreference']
    }
    this.loaderService.presentLoader("Please Wait").then((_) => {
    this.appService.updateUser(this.leadData["customerInfo"]["_id"], obj).subscribe(
      (response) => {
        if (response) {
          // this.storageService.set('acess_token',response.access_token).then(() =>{
            // call back 
          this.loaderService.hideLoader();

            this.modalController.dismiss({searchQueryType: this.searchQueryType, searchQueryCode: this.searchQueryCode});
            // this.router.navigate(['/tabs/home'])
          // })
        }
        (error) => {
          this.loaderService.hideLoader();
        }
      })
    });
  }

}
