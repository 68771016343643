import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteCheckService {
  private currentUrl = new BehaviorSubject<string>(null);
  currentUrl$ = this.currentUrl.asObservable();

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.checkCurrentRoute(event.urlAfterRedirects);
    });
  }

  private checkCurrentRoute(url: string) {
    console.log(">>>>> Routecheck URL >> ", url);
    // if (url.includes('manage-diet')) {
    //   console.log('Currently on Manage Diet route');
    // } else {
    //   console.log('Not on Manage Diet route');
    // }
    this.currentUrl.next(url);
  }
}
