import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
@Injectable({
  providedIn: "root",
})
export class StorageService {
  private _storage: Storage | null = null;
  constructor(private storage: Storage) {
   // this.init();
  }

  // async init() {
  //   // If using, define drivers here: await this.storage.defineDriver(/*...*/);
  //   const storage = await this.storage.create();
  //   this._storage = storage;
  // }

  public set(key, value) {
    return this._storage?.set(key, value);
  }
  public async get(key) {
    return this._storage?.get(key);
  }
  public async remove(key) {
    return await this._storage?.remove(key);
  }
  public clear() {
    this._storage?.clear().then(() => {
      console.log("all keys cleared");
    });
  }
}
