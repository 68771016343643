import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpContext,
  HttpContextToken,
  HttpRequest,
} from "@angular/common/http";
import { APIS } from "../../shared/constants/constants";
import { byPassLog } from "src/app/shared/interceptors/jwt.interceptor";

export const BYPASS_LOG = new HttpContextToken(() => false);
@Injectable({
  providedIn: "root",
})
export class HttpService {
  // BYPASS_LOG = new HttpContextToken(() => false);
  constructor(private httpClient: HttpClient) {}

  post(serviceName: string, data: any = {}) {
    return this.httpClient.post(APIS.baseurl + serviceName, data);
  }

  post4444(serviceName: string, data: any = {}) {
    return this.httpClient.post(APIS.baseurl4444 + serviceName, data);
  }

  put(serviceName: string, data: any = {}) {
    return this.httpClient.put(APIS.baseurl + serviceName, data);
  }

  get(serviceName: string) {
    return this.httpClient.get(APIS.baseurl + serviceName);
  }

  getWithMainUrl(serviceName: string) {
    return this.httpClient.get(APIS.baseurl4444 + serviceName);
  }

  getUser(serviceName: string) {
    return this.httpClient.get(APIS.userBaseUrl + serviceName, {
      context: byPassLog(),
    });
  }
  getUserDefault(serviceName: string) {
    return this.httpClient.get(APIS.userBaseUrl + serviceName);
  }
  delete(serviceName: string) {
    return this.httpClient.delete(APIS.userBaseUrl + serviceName, {
      context: byPassLog(),
    });
  }

  getPlans() {
    return this.httpClient.get(
      APIS.userBaseUrl + `/getCouponList?couponCode=diet`
    );
  }
}
