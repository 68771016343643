import { Component, OnInit } from "@angular/core";
import { AppService as appS } from "../app.service";
import { UTILITIES } from "../core/utility/utilities";
import { AppService } from "../services/app/app.service";
import { AppService as appSer } from "../../app/app.service";
import { Router } from "@angular/router";
import { APIS } from "../shared/constants";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { LoaderService, TemplateService } from "../services";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  profileData: any = {};
  activityName;
  diseases = [];
  allergies = [];
  refAllergies = ["SF,SO", "ML", "F", "E", "N", "G"];
  selectedHeight;
  mealPref;
  lifeStyle: any;
  defaultData: any;
  community = [];
  searchDetails: any;
  search: any = localStorage.getItem("email");
  searchData: any = "";
  planChoosen;
  isSearch = false;
  additionPreferences: any;
  diseasesOptions = [];
  allergyOptions = [];
  dietPlan = [
    { id: "fiteloWaterRetention", name: "Water retention" },
    { id: "fiteloWeightLoss", name: "High Protein fiber" },
    { id: "weightLoss", name: "Weight Loss" },
    { id: "muscleGain_morning", name: "Muscle Gain Morning" },
    { id: "muscleGain_evening", name: "Muscle Gain Evening" },
    { id: "fatShredding_morning", name: "Fat Shredding Morning" },
    { id: "fatShredding_evening", name: "Fat Shredding Evening" },
    { id: "diabetes", name: "Diabetes" },
    { id: "pcos", name: "PCOS" },
    { id: "cholesterol", name: "Cholesterol" },
    { id: "hypertension", name: "Hypertension" },
  ];
  alergyIsHovered: boolean = false;
  foodPrefIsHovered: boolean = false;
  activityIsHovered: boolean = false;
  dieseaseIsHovered: boolean = false;
  updateTargetCal: any;
  recommendedTargetCal: any;
  profileInititals = "";
  isHindi: boolean = true;
  selectedAllergyOption = [];
  selectedDiseasesOption = [];
  countryChoosen: any = null;
  isDietPlanRepeated: boolean = false;
  username: string = "";
  isIndividual = localStorage.getItem("isIndividual");

  constructor(
    private appservice: appS,
    private utilities: UTILITIES,
    private appServe: AppService,
    private appS: appSer,
    private router: Router,
    private iab: InAppBrowser,
    private loaderService: LoaderService,
    private templateService: TemplateService
  ) {}

  ngOnInit() {
    this.username = this.getUserName();
    this.fetchProfile();
    if(localStorage.getItem("email")){
      this.templateService
        .verifyDietPlanRepeat(localStorage.getItem("email"))
        .subscribe(
          (response) => {
            console.log("verifyDietPlanRepeat Response:: ", response);
            this.isDietPlanRepeated = response["repeatForFutureDays"];
            // this.isDietPlanRepeated = true;
          },
          (error) => {
            console.log("VerifyDietPlanRepeat Error: ", error);
            this.isDietPlanRepeated = false;
          }
        );
    }
  }

  getUserName() {
    const email = localStorage.getItem("loginEmail");
    return email.toLowerCase().includes("beatoapp.com".toLowerCase())
      ? "beato"
      : email;
  }

  additionalPref(additionalPref) {
    this.additionPreferences = additionalPref;
  }

  goToClientApp(token) {
    let link = APIS.mainUrl + "?token=" + token;
    // console.log("link", link);
    this.iab.create(link, "_system", "location=yes");
  }
  viewApp() {
    if (!localStorage.getItem("email")) {
      // alert("Please Enter Customer ID");
      return;
    } else {
      this.appServe.getUserToken(localStorage.getItem("email")).subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log("User token ", error.error.text);
          this.goToClientApp(error.error.text);
          localStorage.setItem("tknupdatetarget", error.error.text);
          // console.log("Category Error", error);
        }
      );
    }
  }
  PlanExpiry() {
    this.router.navigate(["/plan-expiry"]);
  }
  fetchProfile() {
    if (!this.search?.trim()) {
      return;
    }
    this.search = localStorage.getItem("email");
    this.isSearch = true;
    this.appS.searchProfile(this.search).then((profileData: any) => {
      this.searchData = profileData["_id"];
      // console.log("profileData:-", profileData);
      this.lifeStyle = profileData?.lifeStyle;
      if(this.lifeStyle){
        this.lifeStyle.firstConsult = false;
      }
      this.additionalPref = profileData["additionalPref"];
      const data = profileData["profile"];

      if (data) {
        this.searchDetails = JSON.parse(JSON.stringify(profileData));
        // console.log("this.searchDetails", this.searchDetails);

        const planC = this.dietPlan.filter((item) => {
          return item.id === this.searchDetails?.additionalPref?.planChosen;
        });
        this.planChoosen = planC[0]?.name;
        this.updateTargetCal = this.searchDetails?.lifeStyle?.calories;
        this.recommendedTargetCal = this.searchDetails?.lifeStyle?.calories;
        const dd = JSON.parse(JSON.stringify(profileData));
        this.profileInititals = dd?.profile.name;
        // dd?.profile.name?.replace("  "," ")?.split(' ')[0][0].toUpperCase() +
        // (dd?.profile?.name?.replace("  "," ")?.split(' ')?.length>=2?dd?.profile?.name?.replace("  "," ")?.split(' ')[1][0].toUpperCase():dd?.profile?.name?.replace("  "," ")?.split(' ')[0][1].toUpperCase());

        this.isHindi =
          profileData["profile"]?.languagePref == undefined ? false : true;
        // this.search ='';
        this.appServe.getUserToken(this.search).subscribe(
          (response) => {
            console.log(response);
          },
          (error) => {
            console.log("User token ", error.error.text);
            localStorage.setItem("personal_token", error.error.text);
            // console.log("Category Error", error);
          }
        );
        this.getProfile();
        this.getToken1();
      } else {
        this.searchDetails = undefined;
      }

      this.appS.getBeatoData(this.search).then(
        (res) => {
          console.log("response", res);
        },
        (err) => {
          console.log("error", err);
        }
      );
    });
  }

  getProfile() {
    this.profileData = [];
    this.appservice.getProfile().then((res) => {
      this.profileData = res;

      if (this.profileData?.profile?.subCategory === "weightloss") {
        this.profileData.profile.subCategory = "Weight Loss";
      }
      if (this.profileData?.profile?.subCategory === "weightmaintenance") {
        this.profileData.profile.subCategory = "Weight Maintenance";
      }
      if (this.profileData?.profile?.subCategory === "musclebuilding") {
        this.profileData.profile.subCategory = "Muscle Building";
      }
      if (this.profileData?.profile?.subCategory === "leanbody") {
        this.profileData.profile.subCategory = "Lean Body";
      }

      let h: any =
        this.profileData?.demographic?.height?.unit === "in"
          ? this.profileData?.demographic?.height?.value / 12
          : this.profileData?.demographic?.height?.value;

      if (this.profileData?.demographic?.height?.unit === "in") {
        // console.log(h);
        h = h.toString().split(".");
        // console.log(h);

        let h1 = "0";
        if (h[1]) {
          h1 = (parseFloat("0." + h[1]) / 0.0833333).toString().split(".")[0];
        }
        // console.log(h1);

        this.profileData.demographic.height.value = `${h[0]}' ${h1}"`;
      } else if(this.profileData.demographic.height) {

        this.profileData.demographic.height.value = `${this.profileData.demographic.height.value} cm`;
      }
      // console.log(this.profileData);
    });
  }

  userToken: string = "";
  getToken1() {
    if (!localStorage.getItem("email")) {
      this.utilities.presentAlert("Please enter email");
    } else {
      this.appServe.getUserToken(localStorage.getItem("email")).subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log("User token ", error.error.text);
          this.userToken = error.error.text;
          this.appS.getDefaultData(error.error.text).then((res) => {
            this.diseases = [];
            this.allergies = [];
            this.refAllergies = ["N", "E", "F", "ML", "SO", "SF", "G"];
            this.mealPref;
            this.community = [];
            this.defaultData = res;
            this.diseasesOptions =
              this.defaultData?.otherMaster?.diseases.filter(
                (item) =>
                  !item.value.endsWith("Allergy") &&
                  !item.value.endsWith("allergy")
              );
            this.allergyOptions =
              this.defaultData?.otherMaster?.diseases.filter(
                (item) =>
                  item.value.endsWith("Allergy") ||
                  item.value.endsWith("allergy")
              );
            this.activityName = this.defaultData.otherMaster.activities.filter(
              (item) => {
                return (
                  item.code ===
                  this.searchDetails?.lifeStyle?.activities["code"]
                );
              }
            );

            if(this.searchDetails["lifeStyle"]) {
              var cCode = this.searchDetails["lifeStyle"]["country"];
              this.countryChoosen = this.defaultData["countries"].find(
                (country) => country._id === cCode
              );
            }
            // console.log(">>> Country hoosen :: ", this.countryChoosen);

            // console.log("Activity Response:: ", this.defaultData);
            // console.log(">> Searh Details>> ", cCode);

            // for (
            //   let index = 0;
            //   index < this.searchDetails?.lifeStyle?.diseases.length;
            //   index++
            // ) {
            //   for (
            //     let j = 0;
            //     j < this.defaultData?.otherMaster?.diseases.length;
            //     j++
            //   ) {
            //     if (
            //       this.defaultData?.otherMaster?.diseases[j].code ===
            //       this.searchDetails?.lifeStyle?.diseases[index] && !this.allergies.includes(
            //         this.defaultData?.otherMaster?.diseases[j].code
            //       )
            //     ) {
            //       if (
            //         this.refAllergies.includes(
            //           this.defaultData?.otherMaster?.diseases[j].code
            //         )
            //       ) {
            //         this.allergies.push(
            //           this.defaultData?.otherMaster?.diseases[j].value
            //         );
            //         alert("added");
            //       } else {
            //         this.diseases.push(
            //           this.defaultData?.otherMaster?.diseases[j].value
            //         );
            //       }
            //     }
            //   }
            // }

            this.searchDetails?.lifeStyle?.diseases.forEach((diseaseCode) => {
              this.defaultData?.otherMaster?.diseases.forEach((disease) => {
                if (disease.code === diseaseCode) {
                  if (this.refAllergies.includes(disease.code)) {
                    this.allergies.push(disease.value);
                    // alert("added");
                  } else {
                    this.diseases.push(disease.value);
                  }
                }
              });
            });

            this.mealPref = this.defaultData?.otherMaster?.foodPref?.filter(
              (item) => {
                return item.code === this.searchDetails?.lifeStyle?.foodType;
              }
            );
            if(this.searchDetails?.lifeStyle?.communities){
              for (
                let index = 0;
                index < this.searchDetails?.lifeStyle?.communities.length;
                index++
              ) {
                for (
                  let j = 0;
                  j < this.defaultData?.otherMaster?.community.length;
                  j++
                ) {
                  if (
                    this.defaultData?.otherMaster?.community[j].code ===
                    this.searchDetails?.lifeStyle?.communities[index]
                  ) {
                    this.community.push(
                      this.defaultData?.otherMaster?.community[j].value
                    );
                  }
                }
              }
            }


            this.selectedDiseasesOption = this.diseases;
          });

          this.appS.getOnePlan1(error.error.text).then((res) => {
            console.log("getOnePlan", res);
          });
          console.log("Category Error", error);
        }
      );
    }
  }

  selectedOptions: any = [];
  onDieseasesOptionSelected(event: any) {
    this.selectedOptions = event.detail.value;
    this.reorderDieseasesOptions();
  }

  reorderDieseasesOptions() {
    this.diseasesOptions.sort((a, b) => {
      const aSelected = this.selectedOptions.includes(a.value);
      const bSelected = this.selectedOptions.includes(b.value);
      return bSelected - aSelected;
    });
  }

  onAllergyOptionSelected(event: any) {
    this.selectedOptions = event.detail.value;
    this.reorderAllergyOptions();
  }

  reorderAllergyOptions() {
    this.allergyOptions.sort((a, b) => {
      const aSelected = this.selectedOptions.includes(a.value);
      const bSelected = this.selectedOptions.includes(b.value);
      return bSelected - aSelected;
    });
  }

  updateProfile(isupdate) {
    this.appServe.getUserToken(this.search).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        this.lifeStyle.firstConsult = isupdate;
        const lifeStyle = this.lifeStyle;
        console.log("User token ", lifeStyle, error.error.text);
        this.appS.postLifeStyle(lifeStyle, error.error.text).then(
          (res) => {
            this.utilities.presentAlert("Updated Successfully!");
            if (localStorage.getItem("dietitianId") != null) {
              this.templateService
                .handleDietitianAction(
                  localStorage.getItem("dietitianId"),
                  "668d70801073b512dd2b9191",
                  localStorage.getItem("email"),
                  "Release Diet Plan",
                  localStorage.getItem("companyId")
                )
                .subscribe((response) => {
                  console.log("profile updated");
                });
            }
          },
          (err) => {
            console.log("failed to updated profile");
          }
        );
      }
    );
  }

  getTokenUpdateTargetCal() {
    if (!localStorage.getItem("email")) {
      // alert("Please Enter Customer ID");
      return;
    } else {
      this.utilities.presentAlertConfirmBox(
        "Update will delete the current date and future date Diet plans. Please confirm to continue.",
        () => {
          this.appServe.getUserToken(localStorage.getItem("email")).subscribe(
            (response) => {
              console.log(response);
            },
            (error) => {
              console.log("User token ", error.error.text);
              this.appS.updateTargetCal(
                this.updateTargetCal,
                this.search,
                error.error.text
              );
              this.recommendedTargetCal = Object.assign(this.updateTargetCal);
              // dietitianId, actionId, userId,  actionName,  companyId
              this.utilities.presentAlert("Calories Updated");
              if (localStorage.getItem("dietitianId") != null) {
                this.templateService
                  .handleDietitianAction(
                    localStorage.getItem("dietitianId"),
                    "668d70ac1073b512dd2b91e2",
                    localStorage.getItem("email"),
                    "Update Calorie",
                    localStorage.getItem("companyId")
                  )
                  .subscribe((response) => {
                    // console.log("Response>> ", response);
                    window.location.reload();
                  });
              } else {
                window.location.reload();
              }
              localStorage.setItem("tknupdatetarget", error.error.text);
              console.log("Category Error", error);
            }
          );
        }
      );
    }
  }

  editFxnClick() {
    this.router.navigate(["edit-profile"]);
  }

  copyProfileLink() {
    // alert(localStorage.getItem("companyKey"))
    // alert(localStorage.getItem("urlClientId"))
    let body: object;
    if (this.isIndividual === "true") {
      body = {
        URLClientID: localStorage.getItem("urlClientId"),
        companyKey: localStorage.getItem("companyKey"),
        userId: this.search,
      };
    } else {
      body = {
        companyKey: localStorage.getItem("companyKey"),
        userId: this.search,
      };
    }
    this.templateService
      .getProfileLink(
        body
        // localStorage.getItem("urlClientId"),
      )
      .subscribe(
        async (response) => {
          await navigator.clipboard.writeText(response["url"]);
          this.utilities.presentAlert("Copied Link");
          // console.log("Response>> ", response);
        },
        (error) => {
          console.log("Generate Profile Link", error.error.text);
        }
      );
  }

  isDietitiian: boolean =
    localStorage.getItem("companyKey") === null ? false : true;

  getDateInToDDMMYYYYFormate(): string {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}${month}${year}`;
  }
  percent=0.0;
  percentwithPer='0%';
  iscloseInterval;
  startInterval(){
   this.iscloseInterval =  setInterval(() => {
    if(this.percent%2===0){
      this.percent = Number(Number(this.percent)+3);
    }
    else{
      this.percent = Number(Number(this.percent)+1);
    }
      this.percentwithPer = this.percent+'%';
      if(this.percent >90){
        clearInterval(this.iscloseInterval);
      }
    }, 1000);
  }
  isdoenloadclicked=false;
  downloadPdf() {
    this.startInterval();
    this.isdoenloadclicked=true;
    const date = this.getDateInToDDMMYYYYFormate();
    const token = localStorage.getItem("acess_token");
    var companyId = localStorage.getItem("companyId");
    if (
      localStorage.getItem("isIndividual") &&
      localStorage.getItem("isIndividual") === "true"
    ) {
      companyId = localStorage.getItem("urlClientId");
    }
    const email = localStorage.getItem("email");
    console.log("Date :: ", date, " || Token:: ", token);
    const dietitianName = localStorage.getItem("dietitianName");
    const dietitianEmail = localStorage.getItem("loginEmail");
    // this.loaderService.presentLoader("Starting Download...").then((_) => {
      // this.templateService.generatePdf(companyId, this.search).subscribe(
      this.appServe.getUserToken(localStorage.getItem("email")).subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log("Pdf Generation User token ", error.error.text);
          this.templateService
            .generatePdf(
              companyId,
              date,
              error.error.text,
              email,
              dietitianName,
              dietitianEmail
            )
            .subscribe(
              (response: Blob) => {
                this.utilities.presentAlert("Pdf Download Starting...");
                // console.log("Response>> ", response);
                // Create a URL for the PDF
                const blob = new Blob([response], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                
                // Create an anchor element and trigger the download
                const a = document.createElement("a");
                a.href = url;

                a.download = `${this.profileData?.profile?.name} ${date}.pdf`;
                document.body.appendChild(a);
                a.click();
                this.percentwithPer='100%';
                // Cleanup
                window.URL.revokeObjectURL(url);
                a.remove();
                setTimeout(()=>{
                  this.isdoenloadclicked=false;
                 },2000);
              },
              (error) => {
                console.log("Generate Pdf Error: ", error);
                // this.utilities.presentAlert("Something went wrong!!");
                // this.loaderService.hideLoader();
                setTimeout(()=>{
                  this.isdoenloadclicked=false;
                 },2000);

                 this.utilities.presentAlert("Failed to download! Please connect with admin.")
              }
            );
        }
      );
  //  });
  }

  disableRepeatForFutureDays() {
    this.utilities.presentAlertConfirmBox(
      "Please confirm if you want the weekly diet plan to stop repeating.",
      () => {
        this.templateService
          .setRepeatForFutureDaysFalse(localStorage.getItem("email"))
          .subscribe(
            (response) => {
              console.log("setRepeatForFutureDaysFalse Response: ", response);
              this.isDietPlanRepeated = false;
            },
            (error) => {
              console.log("setRepeatForFutureDaysFalse Error: ", error);
            }
          );
      }
    );
  }
}
