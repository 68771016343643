import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
@Component({
  selector: 'app-add-update-customer',
  templateUrl: './add-update-customer.component.html',
  styleUrls: ['./add-update-customer.component.scss'],
})
export class AddUpdateCustomerComponent implements OnInit {
  currentCountryCode = "in";
  phoneNumber;
  emailDetail:string;
  name:string;
  isMobile=false;
 
  constructor(private appService:AppService,private iab: InAppBrowser) { 
    
  }
  getValue(event){
    if(event.detail.value=="Mobile"){
      this.isMobile=true;
    }
    else{
      this.isMobile=false;
    }
  }
  ngOnInit() {}
  getToken(){

    let payload={
      "profile": {
        "name":this.name,
        "email": this.emailDetail
      }
    };
    let payload1={
      "profile": {
        "name":this.name,
        "mobile":this.phoneNumber==undefined?"":this.phoneNumber.internationalNumber ,
      }
    };
    
    console.log("payload",this.phoneNumber);
    
    this.appService.externalRegistration("HLTH22531",this.isMobile?payload1:payload).subscribe(res=>{
      console.log("tee:",localStorage.getItem('tkn'));
      this.iab.create(`https://onboarding.smartdietplanner.com/read?token=${localStorage.getItem('tkn')}` , '_system');
        //  window.open(`https://app.smartdietplanner.com?token=${localStorage.getItem('tkn')}`,'_blank');
    });
  }


}
